<template>
	<div class='Wrapper p100px '>
		<p class="title mb20px" v-if="routerCode !== 'ResetPassword' || $store.state.User.ResetPwdStep === '2'">Unlock Account or Reset Password?</p>
		<p class="title mb20px" v-if="routerCode === 'ResetPassword'">Reset Password</p>
		<p class="text" v-if="routerCode !== 'ResetPassword' || $store.state.User.ResetPwdStep === '2'">Please click on the link below to unlock or reset your password:</p>
		<p class="text" v-if="routerCode === 'ResetPassword'">Please click on the link below to reset your password:</p>
		<br/>
		<br/>
		<p class="text" v-html="paragraph1"></p>
		<p class="text">Please input your account email / username as <b style="font-weight: bolder;">repcode@infinitumfa.com</b></p>
		<p class="text">(example: <b style="font-weight: bolder;">Rxxxxxx@infinitumfa.com</b>) to proceed with the request.</p>
		<br/>
		<br/>
		<p class="text">Should you encounter any issues, please contact IT team for assistance and support.</p>
		<br/>
		<br/>
		<p class="text">Thank you.</p>
		<br/>
		<br/>
		<p class="text mb20px">Note : Resetting your password at Microsoft Self-Service Password Reset (SSPR) will result in the reset of passwords for all Infinitum enrolled devices associated with your account.</p>
		<el-button type="info" @click="$router.go(-1)" style="font-weight: bold;font-size: 18px;">Cancel</el-button>
		<!-- CR019不需要重置密码功能 -->
		<el-form :model="resetPwForm" :rules="rules" ref="resetPwForm" style="width: 40%;" v-if="false">
			<!-- 忘记密码 -->
			<template v-if="routerCode !== 'ResetPassword' && $store.state.User.ResetPwdStep === '1'">
				<el-form-item prop="infinitum_rep_code" :rules="[{ required: true, message: 'Please enter Adviser reference ID', trigger: 'blur' }]" style="margin-bottom: 0;">
					<el-input v-model="resetPwForm.infinitum_rep_code" class="mt100px" placeholder="Adviser reference ID"></el-input>
				</el-form-item>
				<el-form-item prop="code" :rules="[{ required: true, message: 'Please enter Code', trigger: 'blur' }]">
					<el-input v-model="resetPwForm.code" placeholder="Code" show-password style="width: 80%"></el-input>
					<el-button @click="sendCode" :disabled="!resetPwForm.infinitum_rep_code" type="info" size="medium" style="width: 145px;">{{buttonText}}</el-button>
				</el-form-item>
			</template>
			<template v-if="$store.state.User.ResetPwdStep === '2'">
				<el-form-item prop="new_password" :rules="[{ required: true, message: 'Please enter New Password', trigger: 'blur' }]">
					<el-input v-model="resetPwForm.new_password" placeholder="New Password" show-password clearable></el-input>
				</el-form-item>
				<el-form-item prop="confirm_new_password">
					<el-input v-model="resetPwForm.confirm_new_password" placeholder="Confirm Password" show-password clearable></el-input>
				</el-form-item>
			</template>
			<!-- 修改密码 -->
			<template v-if="routerCode === 'ResetPassword' && $store.state.User.ResetPwdStep === '1'">
				<el-form-item prop="old_password" :rules="[{ required: true, message: 'Please enter Password', trigger: 'blur' }]">
					<el-input v-model="resetPwForm.old_password" placeholder="Old Password" show-password></el-input>
				</el-form-item>
			</template>
			<el-form-item>
				<el-button type="info" class="w100" @click="routerCode === 'ResetPassword' ? resetCancel() : cancelBtn()" style="font-weight: bold;padding: 20px;font-size: 18px;">Cancel</el-button>
			</el-form-item>
			<!-- 忘记密码确认按钮 -->
			<template v-if="routerCode !== 'ResetPassword'">
				<el-form-item>
					<!-- 填写验证码页面 next -->
					<el-button @click="submit" v-if="$store.state.User.ResetPwdStep === '1'" type="warning" class="w100" :disabled="!resetPwForm.infinitum_rep_code || !resetPwForm.code" style="font-weight: bold;padding: 20px;font-size: 18px;">Next</el-button>
					<!-- 填写密码、确认密码页面 -->
					<el-button @click="confirm" v-else type="warning" class="w100" :disabled="!resetPwForm.new_password || !resetPwForm.confirm_new_password" style="font-weight: bold;padding: 20px;font-size: 18px;">Reset Now</el-button>
				</el-form-item>
			</template>
			<!-- 修改密码确认按钮 -->
			<template v-if="routerCode === 'ResetPassword'">
					<!-- 填写旧密码 next -->
					<el-button @click="verifyOldPwd" v-if="$store.state.User.ResetPwdStep === '1'" type="warning" class="w100" :disabled="!resetPwForm.old_password" style="font-weight: bold;padding: 20px;font-size: 18px;">Next</el-button>
					<!-- 填写新密码 confirm -->
					<el-button @click="confirm('2')" v-if="$store.state.User.ResetPwdStep === '2'" type="warning" class="w100" :disabled="!resetPwForm.confirm_new_password" style="font-weight: bold;padding: 20px;font-size: 18px;">Confirm</el-button>
			</template>

		</el-form>
	</div>
</template>

<script>
import { updatePassword, sendVerification } from 'api/Login'
import { Message } from 'element-ui'
import { GET_username } from '../../utils/localStorage'
export default {
	components: {},
	data() {
		var pwdCheck = async (rules, value, callback) => {
			if (value === '') {
				return callback(new Error('Please enter Confirm Password'))
			} else if (this.resetPwForm.new_password !== value) {
				return callback(new Error('Please enter Same Password'))
			} else {
				callback();
			}
		}
		return {
			paragraph1: "<a href='https://passwordreset.microsoftonline.com' target='_blank' style='color: blue;'>https://passwordreset.microsoftonline.com</a> (Or alternatively, the short link: <a href='https://aka.ms/sspr' target='_blank' style='color: blue;'>https://aka.ms/sspr</a>)",
			buttonText: 'Send Code',
			timer: null,
			show: true,
			routerCode: '',
			resetPwForm: {
				infinitum_rep_code: '',
				code: '',
				old_password: '',
				new_password: '',
				confirm_new_password: '',
			},
			rules: {
				confirm_new_password: [
					{ validator: pwdCheck, trigger: 'blur' }
				]
			}
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 验证码登录
		submit() {
			this.$refs.resetPwForm.validate((valid) => {
				if (!valid) return false
				let param = {
					browser: 'web',
					infinitum_rep_code: this.resetPwForm.infinitum_rep_code,
					// 登录1 忘记密码2
					verificationCodeType: 2,
					verification_code: this.resetPwForm.code,
				}
				this.$store.dispatch('LoginByVerificationCode', param).then(() =>
					// 密码、确认密码页面
					this.$store.commit('SET_RESETPWD_STEP', '2'))
			})
		},
		// 忘记密码修改密码
		confirm(val) {
			this.$refs.resetPwForm.validate((valid) => {
				if (!valid) return false
				updatePassword({ infinitum_rep_code: val === '2' ? GET_username() : this.resetPwForm.infinitum_rep_code, new_password: this.getJsePwd(this.resetPwForm.new_password) }).then(res => {
					if (res.content.reset_password === 1) {
						this.$router.push('/login')
						this.$store.commit('SET_RESETPWD_STEP', '1')
					}
				})
			})
		},
		// 验证旧密码
		verifyOldPwd() {
			let param = {
				browser: 'web',
				infinitum_rep_code: GET_username(),
				password: this.getJsePwd(this.resetPwForm.old_password)
			}
			this.$store.dispatch('LoginByUserName', param).then(() => {
				this.$store.commit('SET_RESETPWD_STEP', '2')
			})
		},
		// 获取加密后的密码
		getJsePwd(pwd) {
			const jse = new this.$jsEncrypt()
			jse.setPublicKey('MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJA/5yMlBmrqR7fM3zVV81QrgbOx6Fm59LPEhZKKAtGm2zxF7Q+tj4odC4zsYmtNlDq2JablbLZl/FFNWoHJu30CAwEAAQ==')
			return jse.encrypt(pwd)
		},
		sendCode() {
			sendVerification({
				infinitum_rep_code: this.resetPwForm.infinitum_rep_code,
				verificationCodeType: 2
			}).then(res => {
				if (res.content.result !== '1' || JSON.stringify(res.content) === '{}') return Message.error(res.rtn_mesg)
				// 60秒倒计时
				const TIME_COUNT = 60
				if (!this.timer) {
					this.buttonText = TIME_COUNT
					this.timer = setInterval(() => {
						if (this.buttonText > 0 && this.buttonText <= TIME_COUNT) {
							this.buttonText--
						} else {
							clearInterval(this.timer)
							this.timer = null
							this.buttonText = 'Send Code'
						}
					}, 1000);
				}
			})
		},
		cancelBtn() {
			// 获取验证码页面
			if (this.$store.state.User.ResetPwdStep === '1') {
				this.$router.push('/login')
			} else {
				// 重置密码页面
				this.$store.commit('SET_RESETPWD_STEP', '1')
			}
		},
		// 重置密码返回
		resetCancel() {
			this.$router.go(-1)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.$store.commit('SET_RESETPWD_STEP', '1')
		if (this.$router.currentRoute.query.name) {
			this.resetPwForm.infinitum_rep_code = this.$router.currentRoute.query.name
		}
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		// 若code为ResetPassword，则是重置密码页面，否则为忘记密码页面
		this.routerCode = this.$router.currentRoute.query.code
	},
}
</script>
<style lang='css' scoped>
.title {
	font-size: 50px;
}
.el-input >>> .el-input__inner {
	height: 64px;
	/* 只显示下边框 */
	border-top-width: 0px;
	border-left-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 1px;
}
.el-form >>> .el-form-item__error {
	font-size: 16px;
}
.el-button--medium {
	padding: 22px 20px;
	font-size: 16px;
	position: absolute;
	right: 0;
}
.text {
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0px;
}
</style>